import React from "react";
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: #948A82;
  color: white;
  padding: 1rem;
  text-align: center;
  font-family: 'Sofia Sans Extra Condensed', sans-serif;
  font-size: 1.3em;
  font-weight: bold;
`;

export default function Footer() {
    return (
        <>
        <FooterContainer>&copy; 2023 Mallory Sherer</FooterContainer>
        </>
    )
}