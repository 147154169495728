import React from "react";
import calculator from '../assets/calculator.gif'
import tictactoe from '../assets/tictactoe.gif'
import todo from '../assets/todo.gif'

export default function Projects({ id }) {

    return (
        <section id={id}>
        <div className="projects">
        <h3 className="section-header">Projects</h3>
        <div className="project-container">
          <img 
            alt="gif demonstrating todo list project" 
            src={todo} className='project' 
            id='project1'  
          ></img>
          <img 
            alt='gif demonstrating calculator project' 
            src={calculator} 
            className='project' 
            id='project2'
          ></img>
          <img 
            alt='gif demonstrating tictactoe project' 
            src={tictactoe} 
            className='project' 
            id='project3'
          ></img>
        </div>
        <div className="project-headers">
          <div className="p1-text">
            <h2 className="p1-header">To-Do List Maker</h2>
            <ul style={{ listStyleType: 'none' }}>
              <li>React</li>
              <li>JavaScript</li>
              <br/>
              <a href="https://zingy-banoffee-4fed3b.netlify.app/" target="_blank" className="project-link" rel="noreferrer">
                <li>Visit Site</li>
              </a>
              <a href="https://github.com/malsherer/todo-app" target="_blank" className="project-link" rel="noreferrer">
                <li>GitHub</li>
              </a>
            </ul>
          </div>
          <div className="p2-text">
            <h2 className="p2-header">Calculator</h2>
            <ul style={{ listStyleType: 'none' }}>
              <li>React</li>
              <li>TypeScript</li>
              <br/>
              <a href="https://github.com/malsherer/calculator-app" target="_blank" className="project-link" rel="noreferrer">
                <li>GitHub</li>
              </a>
            </ul>
          </div>
          <div className="p3-text">
            <h2 className="p3-header">Tic-Tac-Toe</h2>
            <ul style={{ listStyleType: 'none' }}>
              <li>React</li>
              <li>TypeScript</li>
              <br/>
              <a href="https://github.com/malsherer/tic-tac-toe" target="_blank" className="project-link" rel="noreferrer">
                <li>GitHub</li>
              </a>
            </ul>
          </div>
        </div>
      </div>
      </section>
    )
}