import React from "react";
import portfoliopic from '../assets/portfoliopic.png'

export default function About({ id }) {
    return (
        <section id={id}>
        <div className="about" >
        <h3 className="section-header">About</h3>
        <div className="about-main">
          <p className="about-text">Hi, I'm Mallory! I'm a full-stack web developer currently based in Los Angeles. <br/><br/>With experience in languages including JavaScript, TypeScript, and Ruby, as well as frameworks like Rails, PostgreSQL, Next.js and React, I have a passion for finding creative solutions to every day problems.<br/><br/> When I'm not coding, you can catch me cheering for my favorite soccer team, San Diego Wave FC. <br/><br/>Let's collaborate! Reach out to me at mgracesherer@gmail.com, or on <a href="https://github.com/malsherer" target="_blank" rel="noreferrer" className="about-link">GitHub</a> or <a href="https://www.linkedin.com/in/mgsherer" target="_blank" rel="noreferrer" className="about-link">LinkedIn</a></p>
          <div className="about-image-container"><img alt="mallory standing in the woods" src={portfoliopic} className="about-image"></img></div>
        </div>
      </div>
        </section>
    )
}