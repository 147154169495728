import React from "react";

export default function Hero() {
    return (
        <div className="hero">
      <div className="hero-main">
        <div className="hero-image-container"></div>
        <div className="hero-text">
          <h1 className="header-name">Mallory Sherer</h1>
          <h2 className="sub-header">Full-Stack Web Developer</h2>
          <p className="skills-list">Javascript | Ruby | React | Next.js | Tailwind | Rails | PostgreSQL | TypeScript 
          </p>
        </div>
        </div>
      </div>
    )
}