import React from "react";
import { FaLinkedin } from "react-icons/fa6";
import { FaGithub } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";

export default function Contact({ id }) {
    return (
        <section id={id}>
        <div className="contact">
        <h3 className="section-header">Contact</h3>
        <div className="icon-container">
          <a 
            href="https://www.linkedin.com/in/mgsherer"
            target="_blank" 
            rel="noreferrer" 
            id="linkedin">
              <FaLinkedin className="contact-icon" />
          </a>
          <a 
            href="https://github.com/malsherer"
            target="_blank"
            rel="noreferrer"
            id="github">
              <FaGithub className="contact-icon" />
          </a>
          <a 
            href="mailto:mgracesherer@gmail.com"
            target="_blank"
            rel="noreferrer"
            id="email">
              <IoMdMail className="contact-icon"/>
          </a>
        </div>
      </div>
    </section>
    )
}