import React from "react";
import styled from 'styled-components';

const HeaderContainer = styled.header`
  background-color: #FFFFF;
  color: white;
  padding: 1rem;
`;

export default function Header() {
  
    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };
    return (
        <HeaderContainer>
        <ul className="link-container">
          <li onClick={() => scrollToSection('about')} className="link" id="about-link">
            About Me
          </li>
          <li onClick={() => scrollToSection('projects')} className="link" id="projects-link">
            See My Work
          </li>
          <li onClick={() => scrollToSection('contact')} className="link" id="contact-link">
            Contact Me
          </li>
        </ul>
        </HeaderContainer>
    )
}